import React, { ReactNode, useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import { AtLink, OrFooter, OrHeader, AtLinkVariant, AtIcon, AtLinkProps } from '@curran-catalog/curran-atomic-library'

import { ContentfulOrFooter, ContentfulOrHeader, ContentfulObjSeo } from 'types'
import { normalizeFooter, normalizeHeader, normalizeLink } from 'utils/normalize'
import { SidebarContext, SidebarMenuActionTypes } from 'context'
import { useOnlineStatus } from '@hooks/use-online-status'
import { useSessionStorage } from '@hooks/use-session-storage'
import { cartLink as defaultCartLink, defaultCustomerServiceLink, loginLink, tradeProgramLink } from '@config/or-header'
import { SeoHeading } from '@components/seo-heading'
import { useAuth } from '@hooks/use-auth'
import Dropdown from '@components/Dropdown'
import DropdownLink from '@components/DropdownLink'
import { Toaster } from 'react-hot-toast'
import { useCart } from '@hooks/index'
import { detectSiteDomain } from '@utils/detect-site-domain'
import { User } from 'types'

const { synsisalSite } = detectSiteDomain()

export interface LayoutProps {
  seo?: ContentfulObjSeo
  customTitle?: string
  isPreview?: boolean
  slug: string
  header: ContentfulOrHeader
  footer: ContentfulOrFooter
  children?: ReactNode
}

const LIMIT_CART_COUNT = 99
const showExternalSiteLink = !synsisalSite

const PreviewBanner = () => (
  <div className="tw-bg-primary p-2 text-center tw-w-full">
    <AtLink href="/api/clear-preview" variant={AtLinkVariant.WHITE}>
      <h2>Preview mode | Click to exit</h2>
    </AtLink>
  </div>
)

const NoConnectionBanner = () => {
  return (
    <div className="bg-bluedark p-5 sticky top-0 z-30">
      <div className="flex flex-row justify-center items-center gap-2">
        <AtIcon type="wifi-no" color="white" />
        <span className="text-xs text-white">Slow or no internet connection. Please check your internet settings.</span>
      </div>
    </div>
  )
}

export const Layout = ({ seo, customTitle, isPreview, slug, header, footer, children }: LayoutProps) => {
  const { dispatch: sidebarDispatch } = useContext(SidebarContext)
  const { asPath } = useRouter()
  const onlineStatus = useOnlineStatus()
  const [storedAnnouncement, setStoredAnnouncement] = useSessionStorage<boolean>('announcement', true)
  const { user, logout } = useAuth({})
  const { cart } = useCart()

  const customerServiceLink = normalizeLink(header.customerServiceLink)

  const handleOpenMenu = (e: React.MouseEvent) => {
    if (asPath.includes('/customer-service')) {
      e.preventDefault()
      sidebarDispatch({ type: SidebarMenuActionTypes.TOGGLE_MODAL, payload: true })
    }
  }

  const userElement = (
    loginLink: AtLinkProps,
    isSynsisalSite: boolean,
    user?: User,
  ): AtLinkProps | JSX.Element | null => {
    if (isSynsisalSite) return null
    else if (user) {
      return (
        <Dropdown
          key="user-dropdown"
          trigger={
            <button className="flex items-center hover:text-link hover:underline focus:outline-none transition duration-150 ease-in-out">
              <AtIcon className="block lg:hidden" type="user" color="secondary" alt="User icon" size={16} />
              <div className="hidden lg:block">{user?.first_name}</div>

              <div className="ml-1">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </button>
          }
        >
          {/* Authentication */}
          <DropdownLink href="/account">Account</DropdownLink>
          <DropdownLink onClick={logout}>Logout</DropdownLink>
        </Dropdown>
      )
    } else {
      return loginLink
    }
  }

  const userElementMemo = useMemo(
    () => userElement(loginLink, synsisalSite, user),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loginLink, synsisalSite, user],
  )

  const cartCountElement = useMemo(
    () => (
      <div className="flex items-center gap-x-0.5" key="cartCountElement">
        <AtIcon
          type="cart-shopping"
          color="secondary"
          alt="Cart shopping icon"
          size={18}
          className="shrink-0 basis-[18px] grow-0"
        />
        <div
          className={`flex items-center justify-center h-4 ${
            (cart?.count ?? 0) > LIMIT_CART_COUNT ? 'px-1' : 'px-0'
          } py-0.5 min-w-[16px] w-full bg-secondary rounded-full text-white text-xs tw-font-condensed`}
        >
          {cart?.count}
        </div>
      </div>
    ),
    [cart],
  )

  const cartLink = useMemo(
    () =>
      cart?.count ? { ...defaultCartLink, iconElement: cartCountElement, labelClassName: 'sr-only' } : defaultCartLink,
    [cart, cartCountElement],
  )

  return (
    <div>
      <SeoHeading slug={slug} seoObj={seo} customTitle={customTitle} />

      <div className="flex flex-col min-h-screen">
        {!onlineStatus && <NoConnectionBanner />}
        {isPreview && <PreviewBanner />}
        <Toaster position="top-center" />
        <OrHeader
          {...normalizeHeader(header, asPath)}
          handleCustomerServiceClick={handleOpenMenu}
          storedAnnouncement={storedAnnouncement}
          onStoredAnnouncement={(value) => setStoredAnnouncement(value)}
          customerServiceLink={customerServiceLink.actionUrl ? customerServiceLink : defaultCustomerServiceLink}
          tradeProgramLink={tradeProgramLink}
          cartLink={cartLink}
          userElement={userElementMemo}
          currenPath={asPath}
          showExternalSiteLink={showExternalSiteLink}
        />
        <div className="flex-1 md:pb-5 pb-4">{children}</div>
        <OrFooter {...normalizeFooter(footer, asPath)} />
      </div>
    </div>
  )
}
