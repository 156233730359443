/* eslint-disable no-console */
import { Dispatch, SetStateAction } from 'react'
import axios from 'axios'

import { RugBuilderActionTypes } from '@context/rug-builder'
import {
  getCircleAreaPrices,
  getRectangleAreaPrices,
  getWallToWallAreaPrices,
  RectangleAreaPricesDTO,
  CircleAreaPricesDTO,
  WallToWallAreaPricesDTO,
} from '@services/prices'
import { useRugBuilder } from './use-rug-builder'

interface RectangleAreaPrices extends RectangleAreaPricesDTO {
  setLoading: Dispatch<SetStateAction<boolean>>
  setErrors: Dispatch<SetStateAction<unknown>>
  onSuccess: () => void
  onFailure: () => void
  onFinally?: () => void
}

interface CircleAreaPrices extends CircleAreaPricesDTO {
  setLoading: Dispatch<SetStateAction<boolean>>
  setErrors: Dispatch<SetStateAction<unknown>>
  onSuccess: () => void
  onFailure: () => void
  onFinally?: () => void
}

interface WallToWallAreaPrices extends WallToWallAreaPricesDTO {
  setLoading: Dispatch<SetStateAction<boolean>>
  setErrors: Dispatch<SetStateAction<unknown>>
  onSuccess: () => void
  onFailure: () => void
  onFinally?: () => void
}

export const useRugBuilderPrices = () => {
  const { dispatch } = useRugBuilder()

  const fetchRectangleAreaPrices = ({
    setLoading,
    setErrors,
    onSuccess,
    onFailure,
    onFinally,
    ...rest
  }: RectangleAreaPrices) => {
    dispatch({
      type: RugBuilderActionTypes.SET_WALL_TO_WALL_PRICES,
      w2wPricesPayload: undefined,
    })

    setErrors({})
    setLoading(true)

    getRectangleAreaPrices(rest)
      .then((response) => {
        setLoading(false)
        dispatch({
          type: RugBuilderActionTypes.SET_AREA_PRICES,
          areaPricesPayload: response.data,
        })
        onSuccess()
      })
      .catch((error) => {
        setLoading(false)
        if (axios.isAxiosError(error)) {
          if (error?.response?.status !== 422) {
            console.log(error)
            return onFailure()
          }
          setErrors(error?.response?.data)
        }
      })
      .finally(() => {
        onFinally && onFinally()
      })
  }

  const fetchCircleAreaPrices = ({
    setLoading,
    setErrors,
    onSuccess,
    onFailure,
    onFinally,
    ...rest
  }: CircleAreaPrices) => {
    dispatch({
      type: RugBuilderActionTypes.SET_WALL_TO_WALL_PRICES,
      w2wPricesPayload: undefined,
    })
    setErrors({})
    setLoading(true)
    getCircleAreaPrices(rest)
      .then((response) => {
        setLoading(false)
        dispatch({
          type: RugBuilderActionTypes.SET_AREA_PRICES,
          areaPricesPayload: response.data,
        })

        onSuccess()
      })
      .catch((error) => {
        setLoading(false)
        if (axios.isAxiosError(error)) {
          if (error?.response?.status !== 422) {
            console.log(error)
            return onFailure()
          }
          setErrors(error?.response?.data)
        }
      })
      .finally(() => {
        onFinally && onFinally()
      })
  }

  const fetchWallToWallAreaPrices = ({
    setLoading,
    setErrors,
    onSuccess,
    onFailure,
    onFinally,
    ...rest
  }: WallToWallAreaPrices) => {
    dispatch({
      type: RugBuilderActionTypes.SET_AREA_PRICES,
      areaPricesPayload: undefined,
    })

    setErrors({})
    setLoading(true)
    getWallToWallAreaPrices(rest)
      .then((response) => {
        setLoading(false)
        dispatch({
          type: RugBuilderActionTypes.SET_WALL_TO_WALL_PRICES,
          w2wPricesPayload: response.data,
        })

        onSuccess()
      })
      .catch((error) => {
        setLoading(false)
        if (axios.isAxiosError(error)) {
          if (error?.response?.status !== 422) {
            console.log(error)
            return onFailure()
          }
          setErrors(error?.response?.data)
        }
      })
      .finally(() => {
        onFinally && onFinally()
      })
  }

  return {
    fetchRectangleAreaPrices,
    fetchCircleAreaPrices,
    fetchWallToWallAreaPrices,
  }
}
