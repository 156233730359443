import { useContext } from 'react'

import { RugBuilderContext, RugBuilderContextType } from '@context/rug-builder'

export const useRugBuilder = (): RugBuilderContextType => {
  const context = useContext(RugBuilderContext)

  if (context === undefined) {
    throw new Error('useRugBuilder must be used within a RugBuilderContext')
  }

  return context
}
