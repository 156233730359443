import { RefObject, useEffect } from 'react'

import { useRugBuilder } from './use-rug-builder'
import { OrderRugStepperIndexes } from 'types'

type useRugBuilderTopScrollProps<T> = {
  ref: RefObject<T>
  stepIndex: OrderRugStepperIndexes
}

export const useRugBuilderTopScroll = <U extends HTMLElement>({ ref, stepIndex }: useRugBuilderTopScrollProps<U>) => {
  const { state } = useRugBuilder()
  const { activeStep } = state

  useEffect(() => {
    if (activeStep === stepIndex) {
      setTimeout(() => {
        if (ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        }
      }, 10)
    }
  }, [activeStep, ref, stepIndex])
}
