import { useCallback } from 'react'
import { SelectOption } from '@curran-catalog/curran-atomic-library'
import * as Yup from 'yup'

import { FlooringWeaveActionTypes } from '@context/flooring-product'
import { Values } from '@components/flooring-product-detail'
import { useFlooringWeave } from './use-flooring-weave'
import { YupSchemaObject } from './use-form'
import {
  feetInchesToFeetConversion,
  feetInchesToMConversion,
  getFeetInches,
  sqFtToM2Conversion,
} from '@components/rug-builder/order-rug/steps/customize-rug-types/utils'
import { DEFAULT_ERROR_MESSAGE_MAX_INCHES_VALUE, MAX_INCHES_VALUE, MeasureUnitKey } from '@utils/measures'

export const useWallToWall = () => {
  const { state, dispatch } = useFlooringWeave()

  const handleMetricChange = useCallback(() => {
    dispatch({
      type: FlooringWeaveActionTypes.SET_IS_METRIC,
    })
    dispatch({
      type: FlooringWeaveActionTypes.SELECT_UNIT_MEASURE,
      sizeOptionPayload: undefined,
    })
    dispatch({
      type: FlooringWeaveActionTypes.RESET_MEASURE_SIZE_FIELDS,
    })
    dispatch({
      type: FlooringWeaveActionTypes.SET_SIZE_ERROR,
      errorSizePayload: { isError: false, errorMessage: '' },
    })
    dispatch({
      type: FlooringWeaveActionTypes.SET_BASE_PRICE,
      basePricePayload: undefined,
    })
  }, [dispatch])

  const handleUnitMeasure = useCallback(
    (selectedOption: SelectOption) => {
      dispatch({
        type: FlooringWeaveActionTypes.RESET_MEASURE_SIZE_FIELDS,
      })

      dispatch({
        type: FlooringWeaveActionTypes.SET_SIZE_ERROR,
        errorSizePayload: { isError: false, errorMessage: '' },
      })

      dispatch({
        type: FlooringWeaveActionTypes.SET_BASE_PRICE,
        basePricePayload: undefined,
      })

      if (state.size.errorMessage) {
        dispatch({
          type: FlooringWeaveActionTypes.SET_SIZE_ERROR,
          errorSizePayload: undefined,
        })
      }

      dispatch({
        type: FlooringWeaveActionTypes.SELECT_UNIT_MEASURE,
        sizeOptionPayload: selectedOption,
      })
    },
    [dispatch, state.size.errorMessage],
  )

  const sqydSchemaValidation: ({
    blMinLength,
    rollWidth,
  }: {
    blMinLength: number
    rollWidth: number
  }) => YupSchemaObject<Values> = ({ blMinLength, rollWidth }) => ({
    sqyd: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .integer('Decimals are not allowed')
            // Min Validation
            .when(['sqyd'], {
              is: (sqyd: string | number) => Number(sqyd) < Math.round((rollWidth * blMinLength) / 9),
              then: (schema) =>
                schema.min(
                  Math.round((rollWidth * blMinLength) / 9),
                  `Min. ${Math.round((rollWidth * blMinLength) / 9)} sq.yd.`,
                ),
              otherwise: (schema) => schema,
            }),
    ),
    sqft: Yup.lazy(() => Yup.string().notRequired()),
    lft: Yup.lazy(() => Yup.string().notRequired()),
    lftIn: Yup.lazy(() => Yup.string().notRequired()),
    sqm: Yup.lazy(() => Yup.string().notRequired()),
    lm: Yup.lazy(() => Yup.string().notRequired()),
  })

  const sqftSchemaValidation: ({
    blMinLength,
    rollWidth,
  }: {
    blMinLength: number
    rollWidth: number
  }) => YupSchemaObject<Values> = ({ blMinLength, rollWidth }) => ({
    sqyd: Yup.lazy(() => Yup.string().notRequired()),
    sqft: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .required('Required')
            .integer('Decimals are not allowed')
            // Min Validation
            .when(['sqft'], {
              is: (sqft: string | number) => Number(sqft) < Math.round(rollWidth * blMinLength),
              then: (schema) =>
                schema.min(Math.round(rollWidth * blMinLength), `Min. ${Math.round(rollWidth * blMinLength)} sq.ft.`),
              otherwise: (schema) => schema,
            }),
    ),
    lft: Yup.lazy(() => Yup.string().notRequired()),
    lftIn: Yup.lazy(() => Yup.string().notRequired()),
    sqm: Yup.lazy(() => Yup.string().notRequired()),
    lm: Yup.lazy(() => Yup.string().notRequired()),
  })

  const lftInSchemaValidation: ({
    blMinLength,
    messageForMaxInchValue,
  }: {
    blMinLength: number
    messageForMaxInchValue?: string
  }) => YupSchemaObject<Values> = ({ blMinLength, messageForMaxInchValue }) => ({
    sqyd: Yup.lazy(() => Yup.string().notRequired()),
    sqft: Yup.lazy(() => Yup.string().notRequired()),
    lft: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .integer('Decimals are not allowed')
            // Min Validation
            .when(['lft', 'lftIn'], {
              is: (lft: string | number, lftIn: string | number) =>
                feetInchesToFeetConversion(Number(lft), Number(lftIn)) < blMinLength,
              then: (schema) => {
                const { feet, inches } = getFeetInches(blMinLength)
                return schema.min(Math.round(blMinLength), `Min. ${feet}' ${inches}''`)
              },
              otherwise: (schema) => schema,
            }),
    ),
    lftIn: Yup.lazy((value) =>
      value === ''
        ? Yup.string()
        : Yup.number()
            .integer('Decimals are not allowed')
            .max(MAX_INCHES_VALUE, messageForMaxInchValue ?? DEFAULT_ERROR_MESSAGE_MAX_INCHES_VALUE),
    ),
    sqm: Yup.lazy(() => Yup.string().notRequired()),
    lm: Yup.lazy(() => Yup.string().notRequired()),
  })

  const sqmSchemaValidation: ({
    rollWidth,
    blMinLength,
  }: {
    rollWidth: number
    blMinLength: number
  }) => YupSchemaObject<Values> = ({ blMinLength, rollWidth }) => ({
    sqyd: Yup.lazy(() => Yup.string().notRequired()),
    sqft: Yup.lazy(() => Yup.string().notRequired()),
    lft: Yup.lazy(() => Yup.string().notRequired()),
    lftIn: Yup.lazy(() => Yup.string().notRequired()),
    sqm: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .required('Required')
            .integer('Decimals are not allowed')
            // Min validation
            .when(['sqm'], {
              is: (sqm: string | number) => {
                return Number(sqm) < sqFtToM2Conversion({ sqFt: Math.round(rollWidth * blMinLength) })
              },
              then: (schema) => {
                return schema.min(
                  sqFtToM2Conversion({ sqFt: Math.round(rollWidth * blMinLength) }),
                  `Min. ${sqFtToM2Conversion({ sqFt: Math.round(rollWidth * blMinLength) })} m\u00B2.`,
                )
              },
              otherwise: (schema) => schema,
            }),
    ),
    lm: Yup.lazy(() => Yup.string().notRequired()),
  })

  const lmSchemaValidation: ({
    rollWidth,
    blMinLength,
  }: {
    rollWidth: number
    blMinLength: number
  }) => YupSchemaObject<Values> = ({ blMinLength, rollWidth }) => ({
    sqyd: Yup.lazy(() => Yup.string().notRequired()),
    sqft: Yup.lazy(() => Yup.string().notRequired()),
    lft: Yup.lazy(() => Yup.string().notRequired()),
    lftIn: Yup.lazy(() => Yup.string().notRequired()),
    sqm: Yup.lazy(() => Yup.string().notRequired()),
    lm: Yup.lazy((value) =>
      value === ''
        ? Yup.string().required('Required')
        : Yup.number()
            .required('Required')
            .integer('Decimals are not allowed')
            // Min validation
            .when(['lm'], {
              is: (lm: string | number) => {
                return (
                  Number(lm) <
                  sqFtToM2Conversion({ sqFt: Math.round(rollWidth * blMinLength) }) /
                    Number(feetInchesToMConversion(Math.round(rollWidth)))
                )
              },
              then: (schema) => {
                const minValue =
                  sqFtToM2Conversion({ sqFt: Math.round(rollWidth * blMinLength) }) /
                  Number(feetInchesToMConversion(rollWidth))
                return schema.min(Number(minValue.toFixed(2)), `Min. ${minValue.toFixed(2)} m.`)
              },
              otherwise: (schema) => schema,
            }),
    ),
  })

  const imperialSchemaValidation = ({
    measureUnit,
    blMinLength,
    rollWidth,
    messageForMaxInchValue,
  }: {
    measureUnit: MeasureUnitKey
    blMinLength: number
    rollWidth: number
    messageForMaxInchValue?: string
  }) => {
    switch (measureUnit) {
      case MeasureUnitKey.SQUARE_YARD:
        return sqydSchemaValidation({ blMinLength, rollWidth })

      case MeasureUnitKey.SQUARE_FEET:
        return sqftSchemaValidation({ blMinLength, rollWidth })

      default:
        return lftInSchemaValidation({ blMinLength, messageForMaxInchValue })
    }
  }

  const metricSchemaValidation = ({
    measureUnit,
    blMinLength,
    rollWidth,
  }: {
    measureUnit: MeasureUnitKey
    blMinLength: number
    rollWidth: number
  }) => {
    switch (measureUnit) {
      case MeasureUnitKey.SQUARE_METER:
        return sqmSchemaValidation({ blMinLength, rollWidth })

      case MeasureUnitKey.LINEAR_METER:
        return lmSchemaValidation({ blMinLength, rollWidth })

      default:
        return undefined
    }
  }

  return {
    handleMetricChange,
    handleUnitMeasure,
    imperialSchemaValidation,
    metricSchemaValidation,
  }
}
