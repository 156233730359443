import { useContext } from 'react'

import { FlooringSampleContext, FlooringSampleContextType } from '@context/flooring-samples'

export const useSamples = (): FlooringSampleContextType => {
  const context = useContext(FlooringSampleContext)

  if (context === undefined) {
    throw new Error('useSamples must be used within a FlooringSampleContext')
  }

  return context
}
