import Head from 'next/head'

import { environment } from '@config/environment'
import { normalizeAsset } from '@utils/normalize'
import { SiteDomain, SiteConfig, ContentfulObjSeo } from 'types'

export interface SeoHeadingProps {
  seoObj?: ContentfulObjSeo
  slug: string
  customTitle?: string
}

export function getNormalizedURL(slug: string, siteURL: string) {
  const slugTag = slug === '/' ? '' : slug
  const urlTag = `${siteURL?.slice(-1) === '/' && slugTag ? siteURL : siteURL?.slice(0, -1)}${slugTag ?? ''}`
  return urlTag
}

const HeadWrapper = ({ slug, children }: { slug: string; children: React.ReactNode }) => {
  const siteConfig = SiteConfig[environment.siteDomain as SiteDomain]
  const { favicon, siteURL } = siteConfig

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="shortcut icon" href={favicon} type="image/x-icon" />
      <link rel="icon" href={favicon} type="image/x-icon" />
      <meta property="og:url" content={getNormalizedURL(slug, siteURL)} />
      {children}
    </Head>
  )
}

export const SeoHeading = ({ seoObj, customTitle, slug }: SeoHeadingProps) => {
  const titleSiteDomain = SiteConfig[environment.siteDomain as SiteDomain].title
  const fallbackPreviewImage = '/images/ogp-preview.jpg'

  if (!seoObj) {
    const longTitle = SiteConfig[environment.siteDomain as SiteDomain].longTitle
    const title = customTitle ? `${customTitle} | ${titleSiteDomain}` : longTitle

    return (
      <HeadWrapper slug={slug}>
        <title>{title}</title>
        <meta property="og:title" content={title} key="title" />
        <meta property="og:image" content={fallbackPreviewImage} />
      </HeadWrapper>
    )
  }

  const title = `${seoObj.metaTitle} | ${titleSiteDomain}`
  const previewImage =
    Array.isArray(seoObj.socialShareImage) && seoObj.socialShareImage.length > 0
      ? normalizeAsset({ asset: seoObj.socialShareImage[0] })?.src
      : fallbackPreviewImage

  return (
    <HeadWrapper slug={slug}>
      <title>{title}</title>

      <meta property="og:title" content={title} key="title" />
      <meta name="description" content={seoObj.metaDescription} />
      <meta name="og:description" content={seoObj.metaDescription} />

      {seoObj.metaKeywords && seoObj.metaKeywords.length > 0 && (
        <meta property="keywords" content={seoObj.metaKeywords.join(',')} />
      )}

      {previewImage && <meta property="og:image" content={previewImage} />}
      <meta property="og:type" content={seoObj.openGraphType || 'website'} />
      {seoObj.canonicalUrl !== undefined ? (
        <link rel="canonical" href={seoObj.canonicalUrl} />
      ) : (
        environment.siteDomain === SiteDomain.FLOORING && (
          <link rel="canonical" href={getNormalizedURL(slug, environment.sisalcarpetRootUrl as string)} />
        )
      )}
      {seoObj.robots && <meta name="robots" content={seoObj.robots} />}
    </HeadWrapper>
  )
}
