import { useEffect, useMemo } from 'react'
import { captureMessage } from '@sentry/nextjs'

import { FurnitureProductActionTypes } from '../context'
import { useFurnitureProduct } from './use-furniture-product'
import { ErrorFurniturePrices, FurniturePrices, ProductPrices, ContentfulObjFurnitureFrameSlingCol } from '../types'

function doesOptionMatchGroupIdOrIEntryId(
  option: string | null,
  groupId: string | null,
  frameSlingCol: ContentfulObjFurnitureFrameSlingCol,
) {
  return option === frameSlingCol.CONTENTFUL_ID || option === frameSlingCol.groupId
}

type FurniturePriceCalculatorProps = {
  productPrices: FurniturePrices | ErrorFurniturePrices
  hasFabricGrades: boolean
  hasPresetFabrics: boolean
  hasCombos: boolean
  useGroupedOptions?: boolean
}

export const useFurniturePriceCalculator = ({
  productPrices,
  hasPresetFabrics,
  hasCombos,
  hasFabricGrades,
  useGroupedOptions,
}: FurniturePriceCalculatorProps) => {
  const { state, dispatch } = useFurnitureProduct()
  /**
   * (primary|secondary|tertiary|quaternary)ComboFrameSlingCol
   * stands for the Contentful Combo entries
   */
  const {
    presetFabricGrade,
    fabric,
    primaryComboFrameSlingCol,
    secondaryComboFrameSlingCol,
    tertiaryComboFrameSlingCol,
    quaternaryComboFrameSlingCol,
  } = state

  const productPricesResponse = useMemo(() => !!('products' in productPrices) && productPrices, [productPrices])

  const { hasDependencies, hasGrade, hasProductPrices, hasOption1, hasOption2, hasOption3, hasOption4 } = useMemo(
    () => ({
      hasDependencies: productPrices && (productPrices as FurniturePrices).hasDependencies,
      hasProductPrices:
        productPrices &&
        Array.isArray((productPrices as FurniturePrices).products) &&
        (productPrices as FurniturePrices).products.length > 0,
      hasGrade: productPricesResponse && productPricesResponse.products.some((product) => !!product.grade),
      /**
       * If the product's prices that come from the API have frame/sling/color options.
       * This tells the possible combinations a product has
       */
      hasOption1: productPricesResponse && productPricesResponse.products.some((product) => !!product.option1),
      hasOption2: productPricesResponse && productPricesResponse.products.some((product) => !!product.option2),
      hasOption3: productPricesResponse && productPricesResponse.products.some((product) => !!product.option3),
      hasOption4: productPricesResponse && productPricesResponse.products.some((product) => !!product.option4),
    }),
    [productPrices, productPricesResponse],
  )

  // Select options automatically when the page is mounted
  useEffect(() => {
    // The price is added using the first data when there are no options to select.
    if (!useGroupedOptions && !hasDependencies && !hasPresetFabrics && !hasFabricGrades && !hasCombos) {
      if (hasProductPrices) {
        if (
          (productPrices as FurniturePrices).products.every(
            ({ grade, option1, option2, option3, option4 }) => !grade && !option1 && !option2 && !option3 && !option4,
          )
        ) {
          dispatch({
            type: FurnitureProductActionTypes.SET_ORDER_SKU,
            orderSku: (productPrices as FurniturePrices).products[0].sku,
          })
          dispatch({
            type: FurnitureProductActionTypes.SET_BASE_PRICE,
            basePrice: (productPrices as FurniturePrices).products[0].price,
          })
          dispatch({
            type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    useGroupedOptions,
    hasDependencies,
    hasCombos,
    hasFabricGrades,
    hasPresetFabrics,
    hasProductPrices,
    productPrices,
  ])

  // Logic for Combo dependencies to obtain price and order-sku
  // Add price and order-sku according to the selected option
  useEffect(() => {
    if (!useGroupedOptions) {
      if (hasDependencies) {
        // No options coming from either Contentful and from the pricing service.
        if (
          !presetFabricGrade &&
          !fabric &&
          !primaryComboFrameSlingCol &&
          !hasOption1 &&
          !secondaryComboFrameSlingCol &&
          !hasOption2 &&
          !tertiaryComboFrameSlingCol &&
          !hasOption3 &&
          !quaternaryComboFrameSlingCol &&
          !hasOption4
        ) {
          if (hasProductPrices) {
            const foundPrice = (productPrices as FurniturePrices).products[0]

            dispatch({
              type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
              basePriceNotFound: false,
            })
            dispatch({
              type: FurnitureProductActionTypes.SET_ORDER_SKU,
              orderSku: foundPrice.sku,
            })
            dispatch({
              type: FurnitureProductActionTypes.SET_BASE_PRICE,
              basePrice: foundPrice.price,
            })
            dispatch({
              type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
            })
          }
        }

        // Product combination: One Option (1 dependency)
        // primary-combo option selected
        else if (
          !presetFabricGrade &&
          !fabric &&
          primaryComboFrameSlingCol &&
          hasOption1 &&
          !secondaryComboFrameSlingCol &&
          !hasOption2 &&
          !tertiaryComboFrameSlingCol &&
          !hasOption3 &&
          !quaternaryComboFrameSlingCol &&
          !hasOption4
        ) {
          if (hasProductPrices && primaryComboFrameSlingCol.CONTENTFUL_ID) {
            const foundPrice = (productPrices as FurniturePrices).products.find(
              ({ option1 }) => option1 === primaryComboFrameSlingCol.CONTENTFUL_ID,
            )

            if (!foundPrice) {
              captureMessage('Option 1 price not found', {
                tags: {
                  combo1ContentfulId: primaryComboFrameSlingCol.CONTENTFUL_ID,
                },
              })

              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: true,
              })
            } else {
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: false,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_ORDER_SKU,
                orderSku: foundPrice.sku,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE,
                basePrice: foundPrice.price,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
              })
            }
          }
        }

        // Product combination: Multiple Options (2 dependencies)
        // primary-combo option selected and secondary-combo option selected
        else if (
          !presetFabricGrade &&
          !fabric &&
          primaryComboFrameSlingCol &&
          hasOption1 &&
          secondaryComboFrameSlingCol &&
          hasOption2 &&
          !tertiaryComboFrameSlingCol &&
          !hasOption3 &&
          !quaternaryComboFrameSlingCol &&
          !hasOption4
        ) {
          if (
            hasProductPrices &&
            primaryComboFrameSlingCol.CONTENTFUL_ID &&
            secondaryComboFrameSlingCol.CONTENTFUL_ID
          ) {
            const foundPrice = (productPrices as FurniturePrices).products.find(
              ({ option1, option2 }) =>
                option1 === primaryComboFrameSlingCol.CONTENTFUL_ID &&
                option2 === secondaryComboFrameSlingCol.CONTENTFUL_ID,
            )

            if (!foundPrice) {
              captureMessage('Option 1 + Option 2 price not found', {
                tags: {
                  combo1ContentfulId: primaryComboFrameSlingCol.CONTENTFUL_ID,
                  combo2ContentfulId: secondaryComboFrameSlingCol.CONTENTFUL_ID,
                },
              })

              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: true,
              })
            } else {
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: false,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_ORDER_SKU,
                orderSku: foundPrice.sku,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE,
                basePrice: foundPrice.price,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
              })
            }
          }
        }

        // Product combination: Multiple Options (3 dependencies)
        // primary-combo option selected, secondary-combo option selected and tertiary-combo option selected
        else if (
          !presetFabricGrade &&
          !fabric &&
          primaryComboFrameSlingCol &&
          hasOption1 &&
          secondaryComboFrameSlingCol &&
          hasOption2 &&
          tertiaryComboFrameSlingCol &&
          hasOption3 &&
          !quaternaryComboFrameSlingCol &&
          !hasOption4
        ) {
          if (
            hasProductPrices &&
            primaryComboFrameSlingCol.CONTENTFUL_ID &&
            secondaryComboFrameSlingCol.CONTENTFUL_ID &&
            tertiaryComboFrameSlingCol.CONTENTFUL_ID
          ) {
            const foundPrice = (productPrices as FurniturePrices).products.find(
              ({ option1, option2, option3 }) =>
                option1 === primaryComboFrameSlingCol.CONTENTFUL_ID &&
                option2 === secondaryComboFrameSlingCol.CONTENTFUL_ID &&
                option3 === tertiaryComboFrameSlingCol.CONTENTFUL_ID,
            )

            if (!foundPrice) {
              captureMessage('Option 1 + Option 2 + Option 3 price not found', {
                tags: {
                  combo1ContentfulId: primaryComboFrameSlingCol.CONTENTFUL_ID,
                  combo2ContentfulId: secondaryComboFrameSlingCol.CONTENTFUL_ID,
                  combo3ContentfulId: tertiaryComboFrameSlingCol.CONTENTFUL_ID,
                },
              })

              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: true,
              })
            } else {
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: false,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_ORDER_SKU,
                orderSku: foundPrice.sku,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE,
                basePrice: foundPrice.price,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
              })
            }
          }
        }

        // Product combination: Multiple Options (4 dependencies)
        // primary-combo option selected, secondary-combo option selected, tertiary-combo option selected
        // and quaternary-combo option selected
        else if (
          !presetFabricGrade &&
          !fabric &&
          primaryComboFrameSlingCol &&
          hasOption1 &&
          secondaryComboFrameSlingCol &&
          hasOption2 &&
          tertiaryComboFrameSlingCol &&
          hasOption3 &&
          quaternaryComboFrameSlingCol &&
          hasOption4
        ) {
          if (
            hasProductPrices &&
            primaryComboFrameSlingCol.CONTENTFUL_ID &&
            secondaryComboFrameSlingCol.CONTENTFUL_ID &&
            tertiaryComboFrameSlingCol.CONTENTFUL_ID &&
            quaternaryComboFrameSlingCol.CONTENTFUL_ID
          ) {
            const foundPrice = (productPrices as FurniturePrices).products.find(
              ({ option1, option2, option3, option4 }) =>
                option1 === primaryComboFrameSlingCol.CONTENTFUL_ID &&
                option2 === secondaryComboFrameSlingCol.CONTENTFUL_ID &&
                option3 === tertiaryComboFrameSlingCol.CONTENTFUL_ID &&
                option4 === quaternaryComboFrameSlingCol.CONTENTFUL_ID,
            )

            if (!foundPrice) {
              captureMessage('Option 1 + Option 2 + Option 3 + Option 4 price not found', {
                tags: {
                  combo1ContentfulId: primaryComboFrameSlingCol.CONTENTFUL_ID,
                  combo2ContentfulId: secondaryComboFrameSlingCol.CONTENTFUL_ID,
                  combo3ContentfulId: tertiaryComboFrameSlingCol.CONTENTFUL_ID,
                  combo4ContentfulId: quaternaryComboFrameSlingCol.CONTENTFUL_ID,
                },
              })

              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: true,
              })
            } else {
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: false,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_ORDER_SKU,
                orderSku: foundPrice.sku,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE,
                basePrice: foundPrice.price,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
              })
            }
          }
        }

        // Product combination: Grade + Option 1
        // First fabric option selected and first primary-combo option selected
        else if (
          (presetFabricGrade || fabric) &&
          primaryComboFrameSlingCol &&
          hasOption1 &&
          !secondaryComboFrameSlingCol &&
          !hasOption2 &&
          !tertiaryComboFrameSlingCol &&
          !hasOption3 &&
          !quaternaryComboFrameSlingCol &&
          !hasOption4
        ) {
          if (
            hasProductPrices &&
            (fabric?.fabricGradeSku || presetFabricGrade?.sku) &&
            primaryComboFrameSlingCol.CONTENTFUL_ID
          ) {
            const foundPrice = (productPrices as FurniturePrices).products.find(
              ({ grade, option1 }) =>
                option1 === primaryComboFrameSlingCol.CONTENTFUL_ID &&
                (grade === fabric?.fabricGradeSku || grade === presetFabricGrade?.sku),
            )

            if (!foundPrice) {
              captureMessage('Grade + Option 1 price not found', {
                tags: {
                  fabricGradeSku: fabric?.fabricGradeSku || presetFabricGrade?.sku,
                  combo1ContentfulId: primaryComboFrameSlingCol.CONTENTFUL_ID,
                },
              })

              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: true,
              })
            } else {
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: false,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_ORDER_SKU,
                orderSku: foundPrice.sku,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE,
                basePrice: foundPrice.price,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
              })
            }
          }
        }

        // Product combination: Grade + Option 1 + Option 2
        // Fabric option selected, (primary && secondary)-combo option selected
        else if (
          (presetFabricGrade || fabric) &&
          primaryComboFrameSlingCol &&
          hasOption1 &&
          secondaryComboFrameSlingCol &&
          hasOption2 &&
          !tertiaryComboFrameSlingCol &&
          !hasOption3 &&
          !quaternaryComboFrameSlingCol &&
          !hasOption4
        ) {
          if (
            hasProductPrices &&
            (fabric?.fabricGradeSku || presetFabricGrade?.sku) &&
            primaryComboFrameSlingCol.CONTENTFUL_ID
          ) {
            const foundPrice = (productPrices as FurniturePrices).products.find(
              ({ grade, option1, option2 }) =>
                option1 === primaryComboFrameSlingCol.CONTENTFUL_ID &&
                (grade === fabric?.fabricGradeSku || grade === presetFabricGrade?.sku) &&
                option2 === secondaryComboFrameSlingCol.CONTENTFUL_ID,
            )

            if (!foundPrice) {
              captureMessage('Grade + Option 1 + Option 2 price not found', {
                tags: {
                  fabricGradeSku: fabric?.fabricGradeSku || presetFabricGrade?.sku,
                  combo1ContentfulId: primaryComboFrameSlingCol.CONTENTFUL_ID,
                  combo2ContentfulId: secondaryComboFrameSlingCol.CONTENTFUL_ID,
                },
              })

              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: true,
              })
            } else {
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: false,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_ORDER_SKU,
                orderSku: foundPrice.sku,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE,
                basePrice: foundPrice.price,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
              })
            }
          }
        }

        // Product combination: Grade + Option 1 + Option 2 + Option 3
        // Fabric option selected, (primary && secondary && tertiary)-combo option selected
        else if (
          (presetFabricGrade || fabric) &&
          primaryComboFrameSlingCol &&
          hasOption1 &&
          secondaryComboFrameSlingCol &&
          hasOption2 &&
          tertiaryComboFrameSlingCol &&
          hasOption3 &&
          !quaternaryComboFrameSlingCol &&
          !hasOption4
        ) {
          if (
            hasProductPrices &&
            (fabric?.fabricGradeSku || presetFabricGrade?.sku) &&
            primaryComboFrameSlingCol.CONTENTFUL_ID
          ) {
            const foundPrice = (productPrices as FurniturePrices).products.find(
              ({ grade, option1, option2, option3 }) =>
                option1 === primaryComboFrameSlingCol.CONTENTFUL_ID &&
                (grade === fabric?.fabricGradeSku || grade === presetFabricGrade?.sku) &&
                option2 === secondaryComboFrameSlingCol.CONTENTFUL_ID &&
                option3 === tertiaryComboFrameSlingCol.CONTENTFUL_ID,
            )

            if (!foundPrice) {
              captureMessage('Grade + Option 1 + Option 2 + Option 3 price not found', {
                tags: {
                  fabricGradeSku: fabric?.fabricGradeSku || presetFabricGrade?.sku,
                  combo1ContentfulId: primaryComboFrameSlingCol.CONTENTFUL_ID,
                  combo2ContentfulId: secondaryComboFrameSlingCol.CONTENTFUL_ID,
                  combo3ContentfulId: tertiaryComboFrameSlingCol.CONTENTFUL_ID,
                },
              })

              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: true,
              })
            } else {
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: false,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_ORDER_SKU,
                orderSku: foundPrice.sku,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE,
                basePrice: foundPrice.price,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
              })
            }
          }
        }

        // Product combination: Grade + Option 1 + Option 2 + Option 3 + Option 4
        // Fabric option selected, (primary && secondary && tertiary && quaternary)-combo option selected
        else if (
          (presetFabricGrade || fabric) &&
          primaryComboFrameSlingCol &&
          hasOption1 &&
          secondaryComboFrameSlingCol &&
          hasOption2 &&
          tertiaryComboFrameSlingCol &&
          hasOption3 &&
          quaternaryComboFrameSlingCol &&
          hasOption4
        ) {
          if (
            hasProductPrices &&
            (fabric?.fabricGradeSku || presetFabricGrade?.sku) &&
            primaryComboFrameSlingCol.CONTENTFUL_ID
          ) {
            const foundPrice = (productPrices as FurniturePrices).products.find(
              ({ grade, option1, option2, option3, option4 }) =>
                option1 === primaryComboFrameSlingCol.CONTENTFUL_ID &&
                (grade === fabric?.fabricGradeSku || grade === presetFabricGrade?.sku) &&
                option2 === secondaryComboFrameSlingCol.CONTENTFUL_ID &&
                option3 === tertiaryComboFrameSlingCol.CONTENTFUL_ID &&
                option4 === quaternaryComboFrameSlingCol.CONTENTFUL_ID,
            )

            if (!foundPrice) {
              captureMessage('Grade + Option 1 + Option 2 + Option 3 + Option 4 price not found', {
                tags: {
                  fabricGradeSku: fabric?.fabricGradeSku || presetFabricGrade?.sku,
                  combo1ContentfulId: primaryComboFrameSlingCol.CONTENTFUL_ID,
                  combo2ContentfulId: secondaryComboFrameSlingCol.CONTENTFUL_ID,
                  combo3ContentfulId: tertiaryComboFrameSlingCol.CONTENTFUL_ID,
                  combo4ContentfulId: quaternaryComboFrameSlingCol.CONTENTFUL_ID,
                },
              })

              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: true,
              })
            } else {
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: false,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_ORDER_SKU,
                orderSku: foundPrice.sku,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE,
                basePrice: foundPrice.price,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
              })
            }
          }
        } else {
          dispatch({
            type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
            basePriceNotFound: true,
          })
        }
      } else {
        // Product combination: One Option (1 dependency)
        // primary-combo option selected
        if (
          !presetFabricGrade &&
          !fabric &&
          !hasGrade &&
          primaryComboFrameSlingCol &&
          hasOption1 &&
          !secondaryComboFrameSlingCol &&
          !tertiaryComboFrameSlingCol &&
          !quaternaryComboFrameSlingCol
        ) {
          if (hasProductPrices && primaryComboFrameSlingCol.CONTENTFUL_ID) {
            const foundPrice = (productPrices as FurniturePrices).products.find(
              ({ option1 }) => option1 === primaryComboFrameSlingCol.CONTENTFUL_ID,
            )

            if (!foundPrice) {
              captureMessage('Option 1 price not found', {
                tags: {
                  combo1ContentfulId: primaryComboFrameSlingCol.CONTENTFUL_ID,
                },
              })

              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: true,
              })
            } else {
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: false,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_ORDER_SKU,
                orderSku: foundPrice.sku,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE,
                basePrice: foundPrice.price,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
              })
            }
          }
        }

        // Only has 1 option. The product SKU depends on the selected option.
        else if (
          (presetFabricGrade || fabric) &&
          primaryComboFrameSlingCol &&
          hasOption1 &&
          !hasOption2 &&
          !hasOption3 &&
          !hasOption4
        ) {
          if (hasProductPrices && primaryComboFrameSlingCol.CONTENTFUL_ID) {
            // IT SHOULD NOT BE POSSIBLE THAT presetFabricGrade OR fabric ARE DEFINED.
            // IF THE PRODUCT HAS A GRADE AND AT LEAST 1 OPTION,
            // IT MEANS THERE IS A DEPENDENCY BETWEEN THEM.
            // Case supported for some Gloster products.
            const foundPrice = (productPrices as FurniturePrices).products.find(
              ({ option1, grade }) =>
                (presetFabricGrade || fabric
                  ? grade === fabric?.fabricGradeSku || grade === presetFabricGrade?.sku
                  : true) && option1 === primaryComboFrameSlingCol.CONTENTFUL_ID,
            )
            if (!foundPrice) {
              captureMessage('Grade + Option 1 price not found', {
                tags: {
                  fabricGradeSku: fabric?.fabricGradeSku,
                  combo1ContentfulId: primaryComboFrameSlingCol.CONTENTFUL_ID,
                },
              })

              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: true,
              })
            } else {
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: false,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_ORDER_SKU,
                orderSku: foundPrice.sku,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE,
                basePrice: foundPrice.price,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
              })
            }
          }
        }

        // Product combination: Fabric Grade
        // Fabric Grade selected without option dependencies
        else if (presetFabricGrade || fabric) {
          if (hasProductPrices && (presetFabricGrade?.sku || fabric?.fabricGradeSku) && hasGrade) {
            // Based on sku instead CF Id
            const foundPrice = (productPrices as FurniturePrices).products.find(
              ({ grade }) => grade === presetFabricGrade?.sku || grade === fabric?.fabricGradeSku,
            )
            if (!foundPrice) {
              captureMessage('PresetFabric | Fabric Grade price not found', {
                tags: {
                  fabricGradeSku: presetFabricGrade?.sku || fabric?.fabricGradeSku,
                },
              })

              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: true,
              })
            } else {
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
                basePriceNotFound: false,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_ORDER_SKU,
                orderSku: foundPrice.sku,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_BASE_PRICE,
                basePrice: foundPrice.price,
              })
              dispatch({
                type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
              })
            }
          }
        }

        // Product with no fabrics
        // There's no need to check for option dependencies
        else if (!presetFabricGrade && !fabric && !hasGrade) {
          dispatch({
            type: FurnitureProductActionTypes.SET_ORDER_SKU,
            orderSku: (productPrices as FurniturePrices).products[0].sku,
          })
          dispatch({
            type: FurnitureProductActionTypes.SET_BASE_PRICE,
            basePrice: (productPrices as FurniturePrices).products[0].price,
          })
          dispatch({
            type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
          })
        } else {
          dispatch({
            type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
            basePriceNotFound: true,
          })
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    useGroupedOptions,
    hasDependencies,
    hasProductPrices,
    presetFabricGrade,
    fabric,
    primaryComboFrameSlingCol,
    secondaryComboFrameSlingCol,
    tertiaryComboFrameSlingCol,
    quaternaryComboFrameSlingCol,
  ])

  // Logic for Grouped combos to obtain price and order-sku
  useEffect(() => {
    const isGroupedOptions = useGroupedOptions && hasProductPrices

    const isPrimaryComboFrameSlingCol = !!primaryComboFrameSlingCol
    const isSecondaryComboFrameSlingCol = !!secondaryComboFrameSlingCol
    const isTertiaryComboFrameSlingCol = !!tertiaryComboFrameSlingCol
    const isQuaternaryComboFrameSlingCol = !!quaternaryComboFrameSlingCol
    const isPresetFabricGrade = !!presetFabricGrade
    const isFabric = !!fabric

    const failureDispatch = () =>
      dispatch({
        type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
        basePriceNotFound: true,
      })

    const successDispatch = ({ foundPrice }: { foundPrice: ProductPrices }) => {
      dispatch({
        type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
        basePriceNotFound: false,
      })
      dispatch({
        type: FurnitureProductActionTypes.SET_ORDER_SKU,
        orderSku: foundPrice.sku,
      })
      dispatch({
        type: FurnitureProductActionTypes.SET_BASE_PRICE,
        basePrice: foundPrice.price,
      })
      dispatch({
        type: FurnitureProductActionTypes.SET_TOTAL_PRICE,
      })
    }

    if (isGroupedOptions) {
      // Product combination: One Option
      // primary-combo option selected
      if (
        !isPresetFabricGrade &&
        !isFabric &&
        isPrimaryComboFrameSlingCol &&
        !isSecondaryComboFrameSlingCol &&
        !isTertiaryComboFrameSlingCol &&
        !isQuaternaryComboFrameSlingCol
      ) {
        const foundPrice = (productPrices as FurniturePrices).products.find(({ option1, groupId }) =>
          hasDependencies
            ? doesOptionMatchGroupIdOrIEntryId(option1, groupId, primaryComboFrameSlingCol)
            : groupId === primaryComboFrameSlingCol.groupId,
        )

        if (!foundPrice) {
          captureMessage('Grouped Combo 1 price not found', {
            tags: {
              combo1ContentfulId: primaryComboFrameSlingCol.groupId,
            },
          })

          failureDispatch()
        } else {
          successDispatch({ foundPrice })
        }
      }

      // Product combination: Multiple Options (2 dependencies)
      // primary-combo option selected and secondary-combo option selected
      else if (
        !isPresetFabricGrade &&
        !isFabric &&
        isPrimaryComboFrameSlingCol &&
        isSecondaryComboFrameSlingCol &&
        !isTertiaryComboFrameSlingCol &&
        !isQuaternaryComboFrameSlingCol
      ) {
        const foundPrice = (productPrices as FurniturePrices).products.find(({ option1, option2, groupId }) =>
          hasDependencies
            ? doesOptionMatchGroupIdOrIEntryId(option1, groupId, primaryComboFrameSlingCol) &&
              doesOptionMatchGroupIdOrIEntryId(option2, groupId, secondaryComboFrameSlingCol)
            : groupId === (primaryComboFrameSlingCol.groupId ?? secondaryComboFrameSlingCol.groupId),
        )

        if (!foundPrice) {
          captureMessage('Grouped Combo 1 + Grouped Combo 2 price not found', {
            tags: {
              combo1ContentfulId: primaryComboFrameSlingCol.groupId,
              combo2ContentfulId: secondaryComboFrameSlingCol.groupId,
            },
          })

          failureDispatch()
        } else {
          successDispatch({ foundPrice })
        }
      }

      // Product combination: Multiple Options (3 dependencies)
      // primary-combo option selected, secondary-combo option selected and tertiary-combo option selected
      else if (
        !isPresetFabricGrade &&
        !isFabric &&
        isPrimaryComboFrameSlingCol &&
        isSecondaryComboFrameSlingCol &&
        isTertiaryComboFrameSlingCol &&
        !isQuaternaryComboFrameSlingCol
      ) {
        const foundPrice = (productPrices as FurniturePrices).products.find(({ option1, option2, option3, groupId }) =>
          hasDependencies
            ? doesOptionMatchGroupIdOrIEntryId(option1, groupId, primaryComboFrameSlingCol) &&
              doesOptionMatchGroupIdOrIEntryId(option2, groupId, secondaryComboFrameSlingCol) &&
              doesOptionMatchGroupIdOrIEntryId(option3, groupId, tertiaryComboFrameSlingCol)
            : groupId ===
              (primaryComboFrameSlingCol.groupId ??
                secondaryComboFrameSlingCol.groupId ??
                tertiaryComboFrameSlingCol.groupId),
        )
        if (!foundPrice) {
          captureMessage('Grouped Combo 1 + Grouped Combo 2 + Grouped Combo 3 price not found', {
            tags: {
              combo1ContentfulId: primaryComboFrameSlingCol.groupId,
              combo2ContentfulId: secondaryComboFrameSlingCol.groupId,
              combo3ContentfulId: tertiaryComboFrameSlingCol.groupId,
            },
          })

          failureDispatch()
        } else {
          successDispatch({ foundPrice })
        }
      }

      // Product combination: Multiple Options (4 dependencies)
      // primary-combo option selected, secondary-combo option selected, tertiary-combo option selected
      // and quaternary-combo option selected
      else if (
        !isPresetFabricGrade &&
        !isFabric &&
        isPrimaryComboFrameSlingCol &&
        isSecondaryComboFrameSlingCol &&
        isTertiaryComboFrameSlingCol &&
        isQuaternaryComboFrameSlingCol
      ) {
        const foundPrice = (productPrices as FurniturePrices).products.find(
          ({ option1, option2, option3, option4, groupId }) =>
            hasDependencies
              ? doesOptionMatchGroupIdOrIEntryId(option1, groupId, primaryComboFrameSlingCol) &&
                doesOptionMatchGroupIdOrIEntryId(option2, groupId, secondaryComboFrameSlingCol) &&
                doesOptionMatchGroupIdOrIEntryId(option3, groupId, tertiaryComboFrameSlingCol) &&
                doesOptionMatchGroupIdOrIEntryId(option4, groupId, quaternaryComboFrameSlingCol)
              : groupId ===
                (primaryComboFrameSlingCol.groupId ??
                  secondaryComboFrameSlingCol.groupId ??
                  tertiaryComboFrameSlingCol.groupId ??
                  quaternaryComboFrameSlingCol.groupId),
        )

        if (!foundPrice) {
          captureMessage('Grouped Combo 1 + Grouped Combo 2 + Grouped Combo 3 + Grouped Combo 4 price not found', {
            tags: {
              combo1ContentfulId: primaryComboFrameSlingCol.groupId,
              combo2ContentfulId: secondaryComboFrameSlingCol.groupId,
              combo3ContentfulId: tertiaryComboFrameSlingCol.groupId,
              combo4ContentfulId: quaternaryComboFrameSlingCol.groupId,
            },
          })

          failureDispatch()
        } else {
          successDispatch({ foundPrice })
        }
      }

      // Product combination: Grade + Option 1
      // First fabric option selected and first primary-combo option selected
      else if (
        (isPresetFabricGrade || isFabric) &&
        isPrimaryComboFrameSlingCol &&
        !isSecondaryComboFrameSlingCol &&
        !isTertiaryComboFrameSlingCol &&
        !isQuaternaryComboFrameSlingCol
      ) {
        const foundPrice = (productPrices as FurniturePrices).products.find(({ grade, option1, groupId }) =>
          hasDependencies
            ? option1 === primaryComboFrameSlingCol.groupId &&
              (grade === fabric?.fabricGradeSku || grade === presetFabricGrade?.sku)
            : groupId === primaryComboFrameSlingCol.groupId &&
              (grade === fabric?.fabricGradeSku || grade === presetFabricGrade?.sku),
        )
        if (!foundPrice) {
          captureMessage('Grade + Grouped Combo 1 price not found', {
            tags: {
              fabricGradeSku: fabric?.fabricGradeSku || presetFabricGrade?.sku,
              combo1ContentfulId: primaryComboFrameSlingCol.groupId,
            },
          })

          failureDispatch()
        } else {
          successDispatch({ foundPrice })
        }
      }

      // Product combination: Grade + Option 1 + Option 2
      // Fabric option selected, (primary && secondary)-combo option selected
      else if (
        (isPresetFabricGrade || isFabric) &&
        isPrimaryComboFrameSlingCol &&
        isSecondaryComboFrameSlingCol &&
        !isTertiaryComboFrameSlingCol &&
        !isQuaternaryComboFrameSlingCol
      ) {
        const foundPrice = (productPrices as FurniturePrices).products.find(({ grade, option1, option2, groupId }) =>
          hasDependencies
            ? option1 === primaryComboFrameSlingCol.groupId &&
              option2 === secondaryComboFrameSlingCol.groupId &&
              (grade === fabric?.fabricGradeSku || grade === presetFabricGrade?.sku)
            : groupId === (primaryComboFrameSlingCol.groupId ?? secondaryComboFrameSlingCol.groupId) &&
              (grade === fabric?.fabricGradeSku || grade === presetFabricGrade?.sku),
        )

        if (!foundPrice) {
          captureMessage('Grade + Grouped Combo 1 + Grouped Combo 2 price not found', {
            tags: {
              fabricGradeSku: fabric?.fabricGradeSku || presetFabricGrade?.sku,
              combo1ContentfulId: primaryComboFrameSlingCol.groupId,
              combo2ContentfulId: secondaryComboFrameSlingCol.groupId,
            },
          })

          failureDispatch()
        } else {
          successDispatch({ foundPrice })
        }
      }

      // Product combination: Grade + Option 1 + Option 2 + Option 3
      // Fabric option selected, (primary && secondary && tertiary)-combo option selected
      else if (
        (isPresetFabricGrade || isFabric) &&
        isPrimaryComboFrameSlingCol &&
        isSecondaryComboFrameSlingCol &&
        isTertiaryComboFrameSlingCol &&
        !isQuaternaryComboFrameSlingCol
      ) {
        const foundPrice = (productPrices as FurniturePrices).products.find(
          ({ grade, option1, option2, option3, groupId }) =>
            hasDependencies
              ? option1 === primaryComboFrameSlingCol.groupId &&
                option2 === secondaryComboFrameSlingCol.groupId &&
                option3 === tertiaryComboFrameSlingCol.groupId &&
                (grade === fabric?.fabricGradeSku || grade === presetFabricGrade?.sku)
              : groupId ===
                  (primaryComboFrameSlingCol.groupId ??
                    secondaryComboFrameSlingCol.groupId ??
                    tertiaryComboFrameSlingCol.groupId) &&
                (grade === fabric?.fabricGradeSku || grade === presetFabricGrade?.sku),
        )

        if (!foundPrice) {
          captureMessage('Grade + Grouped Combo 1 + Grouped Combo 2 + Grouped Combo 3 price not found', {
            tags: {
              fabricGradeSku: fabric?.fabricGradeSku || presetFabricGrade?.sku,
              combo1ContentfulId: primaryComboFrameSlingCol.groupId,
              combo2ContentfulId: secondaryComboFrameSlingCol.groupId,
              combo3ContentfulId: tertiaryComboFrameSlingCol.groupId,
            },
          })

          failureDispatch()
        } else {
          successDispatch({ foundPrice })
        }
      }

      // Product combination: Grade + Option 1 + Option 2 + Option 3 + Option 4
      // Fabric option selected, (primary && secondary && tertiary && quaternary)-combo option selected
      else if (
        (isPresetFabricGrade || isFabric) &&
        isPrimaryComboFrameSlingCol &&
        isSecondaryComboFrameSlingCol &&
        isTertiaryComboFrameSlingCol &&
        isQuaternaryComboFrameSlingCol
      ) {
        const foundPrice = (productPrices as FurniturePrices).products.find(
          ({ grade, option1, option2, option3, option4, groupId }) =>
            hasDependencies
              ? option1 === primaryComboFrameSlingCol.groupId &&
                option2 === secondaryComboFrameSlingCol.groupId &&
                option3 === tertiaryComboFrameSlingCol.groupId &&
                option4 === quaternaryComboFrameSlingCol.groupId &&
                (grade === fabric?.fabricGradeSku || grade === presetFabricGrade?.sku)
              : groupId ===
                  (primaryComboFrameSlingCol.groupId ??
                    secondaryComboFrameSlingCol.groupId ??
                    tertiaryComboFrameSlingCol.groupId ??
                    quaternaryComboFrameSlingCol.groupId) &&
                (grade === fabric?.fabricGradeSku || grade === presetFabricGrade?.sku),
        )

        if (!foundPrice) {
          captureMessage(
            'Grade + Grouped Combo 1 + Grouped Combo 2 + Grouped Combo 3 + Grouped Combo 4 price not found',
            {
              tags: {
                fabricGradeSku: fabric?.fabricGradeSku || presetFabricGrade?.sku,
                combo1ContentfulId: primaryComboFrameSlingCol.groupId,
                combo2ContentfulId: secondaryComboFrameSlingCol.groupId,
                combo3ContentfulId: tertiaryComboFrameSlingCol.groupId,
                combo4ContentfulId: quaternaryComboFrameSlingCol.groupId,
              },
            },
          )

          failureDispatch()
        } else {
          successDispatch({ foundPrice })
        }
      } else {
        dispatch({
          type: FurnitureProductActionTypes.SET_BASE_PRICE_NOT_FOUND,
          basePriceNotFound: true,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    useGroupedOptions,
    hasDependencies,
    hasProductPrices,
    presetFabricGrade,
    fabric,
    primaryComboFrameSlingCol,
    secondaryComboFrameSlingCol,
    tertiaryComboFrameSlingCol,
    quaternaryComboFrameSlingCol,
  ])
}
