import axios from '@utils/axios'

export const retrieveCsrfToken = async () => {
  axios.get('/sanctum/csrf-cookie')
}

export const getUser = () => {
  return axios.get('/api/user')
}

export interface RegisterDTO {
  first_name: string
  last_name: string
  phone: string
  email: string
  password: string
  password_confirmation: string
  newsletter_signup: boolean
  country_code?: string
  recaptcha: string | null
}

export const sendRegisterRequest = (data: RegisterDTO) => {
  return axios.post('/register', data)
}

export interface LoginDTO {
  email: string
  password: string
  remember: boolean
}

export const sendLoginRequest = (data: LoginDTO) => {
  return axios.post('/login', data)
}

export interface ForgotPasswordDTO {
  email: string
}

export const sendForgotPasswordRequest = (data: ForgotPasswordDTO) => {
  return axios.post('/forgot-password', data)
}

export interface ResetPasswordDTO {
  token?: string | string[] | undefined
  email: string
  password: string
  password_confirmation: string
}

export interface ChangePasswordDTO {
  old_password: string
  password: string
  password_confirmation: string
}

export const sendResetPasswordRequest = (data: ResetPasswordDTO) => {
  return axios.post('/reset-password', data)
}

export const sendChangePasswordRequest = (data: ChangePasswordDTO) => {
  return axios.post('/password/update', data)
}

export const sendResendEmailVerificationRequest = () => {
  return axios.post('/email/verification-notification')
}

export const sendLogoutRequest = async () => {
  return axios.post('/logout')
}
