import { AxiosResponse } from 'axios'
import axios from '@utils/axios'
import { Unit } from '@services/prices'
import { TypeSample } from '@components/rug-builder/order-samples'
import { CartItem } from 'types'

export interface AddonDTO {
  sku: string
  quantity: number
}

export interface CartW2WItemDTO {
  weaveReference: string
  color: string
  unit: Unit
  sqft?: number
  sqm?: number
  productionRush?: boolean
  supplies: AddonDTO[]
  quantity: number
  isWallcovering: boolean
  imageSrc?: string
  imageAlt?: string
  metadata?: {
    link?: string
    notes?: string

    contentful_id?: string
    type?: 'sample'
    subType?: 'weave' | 'border'
    category?: string
    category2?: string
    category3?: string
    category4?: string
    category5?: string
    brand?: string
    itemListName?: string
    index?: number
    query_id?: string
  }
}

export interface CartTilesItemDTO {
  weaveReference: string
  color: string
  supplies: AddonDTO[]
  quantity: number
  imageSrc: string
  imageAlt: string
  metadata?: {
    link?: string

    contentful_id?: string
    type?: 'sample'
    subType?: 'weave' | 'border'
    category?: string
    category2?: string
    category3?: string
    category4?: string
    category5?: string
    brand?: string
    itemListName?: string
    index?: number
    query_id?: string
  }
}

/**
 * Function to add Wall-to-Wall/broadloom item to the cart
 * @param data
 * @returns CartTilesItemDTO
 */
export const sendAddWallToWallToCartRequest = async (data: CartW2WItemDTO) => {
  return axios.post<CartW2WItemDTO, AxiosResponse<CartItem[]>>(`/api/cart/floor/broadloom/add`, data)
}

/**
 * Function to add Tiles item to the cart
 * @param data
 * @returns CartTilesItemDTO
 */
export const sendAddTilesToCartRequest = async (data: CartTilesItemDTO) => {
  return axios.post<CartW2WItemDTO, AxiosResponse<CartItem[]>>(`/api/cart/floor/tiles/add`, data)
}

export interface AreaRugCartDTO {
  weaveReference: string
  imageSrc?: string
  imageAlt?: string
  color: string
  shape: string
  borderReference: string
  borderName: string
  borderColor: string
  borderCornerStyle: string
  borderWidth?: number
  unit: Unit
  widthFt?: number
  widthIn?: number
  widthCm?: number
  lengthFt?: number
  lengthIn?: number
  lengthCm?: number
  diameterFt?: number
  diameterIn?: number
  diameterCm?: number
  rugPadReference?: string
  productionRush: boolean
  supplies?: SupplyDTO[]
  quantity: number
  miteredCorners: boolean
  metadata?: {
    link?: string
    notes?: string

    contentful_id?: string
    type?: 'sample'
    subType?: 'weave' | 'border'

    category?: string
    category2?: string
    category3?: string
    category4?: string
    category5?: string
    brand?: string
    itemListName?: string
    index?: number
    query_id?: string
  }
}

export type SupplyDTO = {
  sku: string
  quantity: number
}

export const sendRugToCartRequest = (data: AreaRugCartDTO) => {
  return axios.post<AreaRugCartDTO, AxiosResponse<CartItem[]>>(`/api/cart/floor/rug/add`, data)
}

export type SampleCartDTO = {
  borderName?: string
  borderReference?: string
  color: string
  imageAlt: string
  imageSrc: string
  quantity: number
  type: TypeSample
  weaveReference: string
  metadata: {
    contentful_id: string
    type: 'sample'
    subType: 'weave' | 'border'
    link?: string

    category?: string
    category2?: string
    category3?: string
    category4?: string
    category5?: string
    brand?: string
    itemListName?: string
    index?: number
    query_id?: string
  }
}

/**
 * Function to add sample item to the cart
 * @param data
 * @returns CartResponse
 */
export const sendAddSampleToCartRequest = async (data: SampleCartDTO) => {
  return axios.post<SampleCartDTO, AxiosResponse<CartItem[]>>(`/api/cart/floor/sample/add`, data)
}
