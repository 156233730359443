import { Menu } from '@headlessui/react'
import { AtLink, AtLinkVariant } from '@curran-catalog/curran-atomic-library'
import { renderLinkWrapper } from '@utils/link-wrapper'

const DropdownLink = ({ children, href, onClick }: { children: string; href?: string; onClick?: () => void }) => (
  <Menu.Item>
    <AtLink
      className="w-full text-left block px-4 py-2 text-sm leading-5"
      variant={AtLinkVariant.DEFAULT}
      label={children}
      onClick={onClick}
      actionUrl={href}
      linkWrapper={renderLinkWrapper}
    />
  </Menu.Item>
)

export default DropdownLink
