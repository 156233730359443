import { OrModalProductProps } from '@curran-catalog/curran-atomic-library'
import { useState } from 'react'

export const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalErrorOpen, setIsModalErrorOpen] = useState(false)
  const [modalInfo, setModalInfo] = useState<{ productContent: OrModalProductProps | undefined }>()

  const handlerClick = (infoProduct?: OrModalProductProps, isError?: boolean) => {
    if (!isError) {
      setModalInfo({ productContent: infoProduct })
      setIsModalOpen(true)
    } else setIsModalErrorOpen(true)
  }

  return { isModalOpen, setIsModalOpen, modalInfo, handlerClick, isModalErrorOpen, setIsModalErrorOpen }
}
