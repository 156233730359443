import { AxiosResponse } from 'axios'
import axios from '@utils/axios'
import { Cart, CartItem } from 'types'

export interface ErrorResponse {
  message: string
  errors: { [key: string]: string }
}

export interface FurnitureCartItem {
  sku: string
  quantity: number
  description: string
  details: string
  image_src?: string
  image_alt?: string
  price?: number
  metadata?: {
    link?: string
    category?: string
    category2?: string
    brand?: string
    itemListName?: string
    index?: number
    contentful_id?: string
    query_id?: string
  }
}

export interface FurnitureCartDTO extends FurnitureCartItem {
  childItems?: FurnitureCartItem[]
  optionalItems?: FurnitureCartItem[]
}

export const sendAddFurnitureProductToCartRequest = (data: FurnitureCartDTO) => {
  return axios.post<FurnitureCartDTO, AxiosResponse<CartItem[]>>('/api/cart/furniture/add', data)
}

export interface UpdateCartItemDTO {
  id: number
  quantity: number
}

export const sendUpdateItemQuantityRequest = async ({ id, quantity }: UpdateCartItemDTO) => {
  return axios.put<UpdateCartItemDTO, AxiosResponse<Cart>>('api/cart/' + id + '/update', { quantity })
}
export interface RemoveItemDTO {
  id: number
}

export const sendRemoveItemRequest = async ({ id }: RemoveItemDTO) => {
  return axios.delete<RemoveItemDTO, AxiosResponse<Cart>>('api/cart/' + id + '/remove')
}

export const sendSendCartRequest = async () => {
  return axios.post<AxiosResponse<CartItem>>('api/cart/send')
}

export interface EstimateTaxDTO {
  zip: string
}

export const sendEstimateTaxRequest = async ({ zip }: EstimateTaxDTO) => {
  return axios.post<EstimateTaxDTO, AxiosResponse<Cart>>('api/cart/estimate-tax', { zip })
}

export interface ApplyPromoDTO {
  code: string
}

export const sendApplyPromoRequest = async ({ code }: ApplyPromoDTO) => {
  return axios.post<ApplyPromoDTO, AxiosResponse<Cart>>('/api/promo/' + code + '/apply')
}

export const sendRemovePromoRequest = async () => {
  return axios.post<undefined, AxiosResponse<Cart>>('/api/promo/remove')
}
