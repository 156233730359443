import { useContext } from 'react'

import { FurnitureProductContextType, FurnitureProductContext } from 'context'

export const useFurnitureProduct = (): FurnitureProductContextType => {
  const context = useContext(FurnitureProductContext)

  if (context === undefined) {
    throw new Error('useFurnitureProduct must be used within a FurnitureProductContext')
  }

  return context
}
