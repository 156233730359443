import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

// This custom hook handles the back route when the user clicks back on a page on /catalog.

export const useHandleBackButtonPagination = () => {
  const router = useRouter()
  const queryParamsRef = useRef(router.query)

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    const handleQueryChange = () => {
      // Compare the current query parameters with the stored query parameters.
      const storedParams = queryParamsRef.current
      const currentParams = router.query

      const pageChanged = storedParams.page !== currentParams.page
      const catalogParamsChanged = Object.keys(storedParams).some(
        (key) => key !== 'page' && storedParams[key] !== currentParams[key],
      )

      if (pageChanged || catalogParamsChanged) {
        // Extract the new page number from the query parameter or default to 1.
        const _newPage = parseInt(currentParams.page as string, 10) || 1

        // Go back in history.
        window.history.go(-1)
      }

      // Update the stored query parameters.
      queryParamsRef.current = currentParams
    }

    const beforePopStateHandler = () => {
      handleQueryChange()
      return true
    }

    router.beforePopState(beforePopStateHandler)

    return () => {
      router.beforePopState(() => true)
    }
  }, [router])

  return null
}
