import { useState } from 'react'
import useSWR from 'swr'
import { AxiosError } from 'axios'
import toast from 'react-hot-toast'

import {
  sendAddFurnitureProductToCartRequest,
  UpdateCartItemDTO,
  sendUpdateItemQuantityRequest,
  ErrorResponse,
  sendRemoveItemRequest,
  RemoveItemDTO,
  sendEstimateTaxRequest,
  EstimateTaxDTO,
  sendApplyPromoRequest,
  ApplyPromoDTO,
  sendRemovePromoRequest,
  AreaRugCartDTO,
  CartW2WItemDTO,
  SampleCartDTO,
  sendAddSampleToCartRequest,
  sendAddWallToWallToCartRequest,
  sendRugToCartRequest,
  sendAddTilesToCartRequest,
  CartTilesItemDTO,
  FurnitureCartDTO,
} from '@services/cart'
import { retrieveCsrfToken } from '@services/auth'
import fetcher from '@utils/fetcher'
import { Cart } from 'types'
import { useSessionStorage } from './use-session-storage'

export const useCart = (doNotRefresh = false) => {
  const [promoError, setPromoError] = useState('')
  const [_, setPromoCode] = useSessionStorage('promoCodeApply', null)

  const swrOptions = {
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    revalidateIfStale: true,
  }

  if (doNotRefresh) {
    swrOptions.revalidateOnReconnect = false
    swrOptions.revalidateOnFocus = false
    swrOptions.revalidateIfStale = false
  }

  const { data: cart, error, mutate, isLoading, isValidating } = useSWR<Cart>('/api/cart', fetcher, swrOptions)

  const addFurnitureProductToCart = async (props: FurnitureCartDTO) => {
    await retrieveCsrfToken()

    const response = await sendAddFurnitureProductToCartRequest(props).then((response) => response.data)

    mutate()

    return response
  }

  const addAreaRugToCart = async (props: AreaRugCartDTO) => {
    await retrieveCsrfToken()

    const response = await sendRugToCartRequest(props).then((response) => response.data)

    mutate()

    return response
  }

  const addWallToWallToCart = async (props: CartW2WItemDTO) => {
    await retrieveCsrfToken()

    const response = await sendAddWallToWallToCartRequest(props).then((response) => response.data)

    mutate()

    return response
  }

  const addTilesToCart = async (props: CartTilesItemDTO) => {
    await retrieveCsrfToken()

    const response = await sendAddTilesToCartRequest(props).then((response) => response.data)

    mutate()

    return response
  }

  const addSampleToCart = async (props: SampleCartDTO) => {
    await retrieveCsrfToken()

    const response = await sendAddSampleToCartRequest(props).then((response) => response.data)

    mutate()

    return response
  }

  const updateQuantity = async ({ id, quantity }: UpdateCartItemDTO) => {
    await retrieveCsrfToken()

    const updatedItems =
      cart?.items.map((item) => {
        if (item.id === id) {
          return { ...item, quantity }
        }

        return item
      }) ?? []

    const options = {
      optimisticData: cart ? { ...cart, items: updatedItems, isUpdatingTotals: true } : undefined,
      rollbackOnError: true,
      revalidate: false,
    }

    try {
      const dataFetch = sendUpdateItemQuantityRequest({ id, quantity }).then((response) => response.data)
      await mutate(dataFetch, options)
      return dataFetch
    } catch (error) {
      toast.error('Failed to update item quantity')
    }
    return cart
  }

  const removeItem = async ({ id }: RemoveItemDTO) => {
    await retrieveCsrfToken()

    const options = {
      optimisticData: cart
        ? { ...cart, items: cart.items.filter((item) => item.id !== id), isUpdatingTotals: true }
        : undefined,
      rollbackOnError: true,
      revalidate: false,
    }

    await mutate(
      sendRemoveItemRequest({ id }).then((response) => response.data),
      options,
    )
  }

  const estimateTax = async ({ zip }: EstimateTaxDTO) => {
    await retrieveCsrfToken()
    mutate(
      sendEstimateTaxRequest({ zip }).then((response) => response.data),
      { optimisticData: cart ? { ...cart, isUpdatingTotals: true } : undefined },
    )
  }

  const applyPromo = async ({ code }: ApplyPromoDTO) => {
    await retrieveCsrfToken()

    setPromoError('')

    try {
      await mutate(
        sendApplyPromoRequest({ code }).then((response) => response.data),
        { optimisticData: cart ? { ...cart, isUpdatingTotals: true } : undefined },
      )
      setPromoCode(code)
    } catch (e: unknown) {
      const error = e as AxiosError<ErrorResponse>

      if (!error.response) return

      if (error.response.status === 404) {
        setPromoError('Code is expired or invalid.')
      } else {
        setPromoError(error.response.data.message)
      }
    }
  }

  const removePromo = async () => {
    await retrieveCsrfToken()

    try {
      await mutate(
        sendRemovePromoRequest().then((response) => response.data),
        { optimisticData: cart ? { ...cart, activePromoCode: undefined, isUpdatingTotals: true } : undefined },
      )
    } catch (e: unknown) {
      const error = e as AxiosError<ErrorResponse>

      if (!error.response) return
      toast.error(error.response.data.message)
    }
  }

  return {
    addAreaRugToCart,
    addFurnitureProductToCart,
    addSampleToCart,
    addTilesToCart,
    addWallToWallToCart,
    applyPromo,
    cart,
    estimateTax,
    error,
    promoError,
    removeItem,
    removePromo,
    updateQuantity,
    isLoading,
    isValidating,
  }
}
