import useSWR from 'swr'
import {
  retrieveCsrfToken,
  getUser,
  sendForgotPasswordRequest,
  sendLoginRequest,
  sendLogoutRequest,
  sendRegisterRequest,
  sendResendEmailVerificationRequest,
  sendResetPasswordRequest,
  sendChangePasswordRequest,
  RegisterDTO,
  LoginDTO,
  ForgotPasswordDTO,
  ResetPasswordDTO,
  ChangePasswordDTO,
} from '@services/auth'
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { User } from 'types'

interface AuthConfiguration {
  middleware?: string
  redirectIfAuthenticated?: string
}

interface RegisterProps extends RegisterDTO {
  setErrors: Dispatch<SetStateAction<RegisterValidationErrors>>
}

interface LoginProps extends LoginDTO {
  setErrors: Dispatch<SetStateAction<LoginValidationErrors>>
  setStatus: Dispatch<SetStateAction<string | null>>
}

interface ForgotPasswordProps extends ForgotPasswordDTO {
  setErrors: Dispatch<SetStateAction<ForgotPasswordValidationErrors>>
  setStatus: Dispatch<SetStateAction<string | null>>
}

interface ResetPasswordProps extends ResetPasswordDTO {
  setErrors: Dispatch<SetStateAction<PasswordResetValidationErrors>>
  setStatus: Dispatch<SetStateAction<string | null>>
}

interface ChangePasswordProps extends ChangePasswordDTO {
  setErrors: Dispatch<SetStateAction<PasswordChangeValidationErrors | null>>
  setStatus: Dispatch<SetStateAction<number | null>>
}

interface ResendEmailVerificationProps {
  setStatus: Dispatch<SetStateAction<null>>
}

export interface RegisterValidationErrors {
  firstName?: string
  lastName?: string
  email?: string
  password?: string
  phone?: string
  recaptcha?: string
}

export interface LoginValidationErrors {
  email?: string
  password?: string
}

export interface ForgotPasswordValidationErrors {
  email?: string
}

export interface PasswordResetValidationErrors {
  email?: string
  password?: string
  password_confirmation?: string
}

export interface PasswordChangeValidationErrors {
  old_password?: string[]
  password?: string[]
  password_confirmation?: string[]
}

export const useAuth = ({ middleware, redirectIfAuthenticated }: AuthConfiguration) => {
  const router = useRouter()

  const redirectIfAuthenticatedPath = router.query.intended?.toString() || redirectIfAuthenticated

  const {
    data: user,
    error,
    mutate,
    isLoading,
  } = useSWR<User>('/api/user', () =>
    getUser()
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.status !== 422) throw error

        router.push('/verify-email')
      }),
  )

  const isLoggedOut = error && error.response?.status === 401

  const register = async ({ setErrors, ...props }: RegisterProps) => {
    await retrieveCsrfToken()

    setErrors({})

    sendRegisterRequest(props)
      .then(() => mutate())
      .catch((error) => {
        if (error.response.status !== 422) throw error

        setErrors(error.response.data.errors)
      })
  }

  const login = async ({ setErrors, setStatus, ...props }: LoginProps) => {
    await retrieveCsrfToken()

    setErrors({})
    setStatus(null)

    return sendLoginRequest(props)
      .then(() => {
        mutate()
      })
      .catch((error) => {
        if (error.response.status !== 422) throw error

        setErrors(error.response.data.errors)
      })
  }

  const forgotPassword = async ({ setErrors, setStatus, email }: ForgotPasswordProps) => {
    await retrieveCsrfToken()

    setErrors({})
    setStatus(null)

    sendForgotPasswordRequest({ email })
      .then((response) => setStatus(response.data.status))
      .catch((error) => {
        if (error.response.status !== 422) throw error

        setErrors(error.response.data.errors)
      })
  }

  const changePassword = async ({ setErrors, setStatus, ...props }: ChangePasswordProps) => {
    await retrieveCsrfToken()

    setErrors(null)
    setStatus(null)

    sendChangePasswordRequest({ ...props })
      .then((response) => {
        setStatus(response.status)
      })
      .catch((error) => {
        if (error.response.status !== 422) throw error

        setErrors(error.response.data.errors as PasswordChangeValidationErrors)
      })
  }

  const resetPassword = async ({ setErrors, setStatus, ...props }: ResetPasswordProps) => {
    await retrieveCsrfToken()

    setErrors({})
    setStatus(null)

    sendResetPasswordRequest({ token: router.query.token, ...props })
      .then((response) => router.push('/login?reset=' + btoa(response.data.status)))
      .catch((error) => {
        if (error.response.status !== 422) throw error

        setErrors(error.response.data.errors)
      })
  }

  const resendEmailVerification = ({ setStatus }: ResendEmailVerificationProps) => {
    sendResendEmailVerificationRequest().then((response) => setStatus(response.data.status))
  }

  const logout = useCallback(async () => {
    if (!error) {
      await sendLogoutRequest().then(() => mutate())
    }

    window.location.pathname = '/login'
  }, [error, mutate])

  useEffect(() => {
    if (middleware === 'guest' && redirectIfAuthenticatedPath && user) router.push(redirectIfAuthenticatedPath)
    if (middleware === 'auth' && error) logout()
  }, [middleware, redirectIfAuthenticatedPath, logout, user, error, router])

  return {
    user,
    isLoading,
    isLoggedOut,
    register,
    login,
    forgotPassword,
    changePassword,
    resetPassword,
    resendEmailVerification,
    logout,
  }
}
