import { useContext } from 'react'

import { FlooringWeaveContextType, FlooringWeaveContext } from 'context'

export const useFlooringWeave = (): FlooringWeaveContextType => {
  const context = useContext(FlooringWeaveContext)

  if (context === undefined) {
    throw new Error('useFlooringWeave must be used within a FlooringWeaveContext')
  }

  return context
}
