import { AtLinkProps, OrTabItemProps, OrTabsProps, OrTabsVariant } from '@curran-catalog/curran-atomic-library'
import { renderLinkWrapper } from '@utils/link-wrapper'
import { SiteConfig, SiteDomain } from 'types'
import { environment } from './environment'

export const defaultCustomerServiceLink: AtLinkProps = {
  actionUrl: '/customer-service',
  linkWrapper: renderLinkWrapper,
  label: 'Need Help?',
  icon: { type: 'user-headset', size: 18, color: 'white' },
}

export const tabLinks: OrTabItemProps[] = [
  {
    title: {
      label: 'FLOOR',
      actionUrl:
        environment.siteDomain !== SiteDomain.FURNITURE
          ? '/'
          : SiteConfig[environment.siteDomain as SiteDomain].tabFloorURL,
    },
    active: environment.siteDomain !== SiteDomain.FURNITURE,
  },
  {
    title: {
      label: 'HOME',
      actionUrl:
        environment.siteDomain === SiteDomain.FURNITURE
          ? '/'
          : SiteConfig[environment.siteDomain as SiteDomain].tabHomeURL,
    },
    active: environment.siteDomain === SiteDomain.FURNITURE,
  },
]

export const headerTabs: OrTabsProps = {
  tabs: tabLinks,
  variant: 'secondary' as OrTabsVariant,
}

export const tradeProgramLink: AtLinkProps | null =
  environment.siteDomain === SiteDomain.SYNSISAL
    ? null
    : {
        actionUrl: '/trade',
        linkWrapper: renderLinkWrapper,
        label: 'Trade Program',
      }

export const loginLink: AtLinkProps = {
  actionUrl: '/login',
  linkWrapper: renderLinkWrapper,
  label: 'Sign in',
  icon: { type: 'user', color: 'secondary', alt: 'Sign in' },
}

export const cartLink: AtLinkProps = {
  actionUrl: '/cart',
  linkWrapper: renderLinkWrapper,
  label: 'Cart',
  icon: { type: 'cart-shopping', color: 'secondary', alt: 'Cart shopping icon' },
}
