import { useEffect, useState } from 'react'

const PING_RESOURCE = '/ping.txt'
const ABORT_FETCH_TIMEOUT_MS = 3000
const FETCH_TIMEOUT_MS = 10000

const timeout = (time: number, promise: Promise<Response>) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject()
    }, time)
    promise.then(resolve, reject)
  })
}

const checkOnlineStatus = async () => {
  const controller = new AbortController()
  const { signal } = controller

  // If the browser has no network connection return offline
  if (!navigator.onLine) return navigator.onLine

  try {
    await timeout(
      ABORT_FETCH_TIMEOUT_MS,
      fetch(PING_RESOURCE, {
        method: 'GET',
        signal,
      }),
    )
    return true
  } catch {
    // In case of request timed out, the request is aborted
    controller.abort()
  }

  return false
}

export const useOnlineStatus = () => {
  const [onlineStatus, setOnlineStatus] = useState<boolean>(true)

  const checkStatus = async () => {
    const online = await checkOnlineStatus()
    setOnlineStatus(online)
  }

  useEffect(() => {
    window.addEventListener('offline', () => {
      setOnlineStatus(false)
    })

    // Add polling incase of slow connection
    const id = setInterval(() => {
      checkStatus()
    }, FETCH_TIMEOUT_MS)

    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(false)
      })

      clearInterval(id)
    }
  }, [])

  return onlineStatus
}
